<template>
  <offer
    v-if="isEditMode"
    v-click-outside="onClickOutside"
    :offer-id="offerId"
    :offer-data="offerData"
    @updateValue="$emit('updateValue', $event)"
    @save="$emit('save')"
  />
  <b-button
    v-else
    class="p-0 border-0 font-weight-normal"
    variant="link"
    @click="onClickButton"
  >
    {{ buttonText }}
    <template v-if="offerData.measurementUnit !== 'portion'">
      / {{ offerData.amount }} {{ offerData.measurementUnit }}
    </template>
  </b-button>
</template>

<script>
import {
  BButton,
} from 'bootstrap-vue'
import Offer from '@/components/Offer.vue'
import mixinFormatter from '@/mixins/formatter'
import { mapGetters } from 'vuex'
import directiveClickOutside from '@/directives/clickOutside'

export default {
  name: 'MenuOffer',
  directives: {
    clickOutside: directiveClickOutside,
  },
  components: {
    BButton,
    Offer,
  },
  mixins: [
    mixinFormatter,
  ],
  props: {
    offerId: {
      type: String,
      required: true,
      default: '',
    },
    offerData: {
      type: Object,
      required: true,
      default: () => {},
    },
  },
  data() {
    return {
      isEditMode: false,
    }
  },
  computed: {
    ...mapGetters({
      currencySymbol: 'restaurant/currencySymbol',
    }),
    buttonText() {
      let res = ''

      if (this.currencySymbol) {
        res += this.currencySymbol
      }

      res += this.getFormattedPrice(this.offerData.price)

      return res
    },
  },
  methods: {
    onClickButton() {
      setTimeout(() => {
        this.isEditMode = true
      })
    },
    onClickOutside() {
      this.isEditMode = false
    },
  },
}
</script>
