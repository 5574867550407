<template>
  <b-row class="menu-content-text">
    <b-col>
      <div class="d-flex align-items-center">
        <div class="card-content__move-vertical-icon card-content__transparent mr-1">
          <feather-icon
            size="20"
            icon="MoreVerticalIcon"
          />
          <feather-icon
            size="20"
            icon="MoreVerticalIcon"
          />
        </div>
        <validation-provider
          #default="{ errors }"
          ref="vp"
          tag="div"
          :class="[
            'menu-content-text__container',
            { 'menu-content-text__container_textarea-in-focus': isTextareaInFocus },
          ]"
          name="add_text"
          :vid="item.id"
          :rules="`max:${textLimit}`"
        >
          <b-form-textarea
            class="menu-content-text__textarea"
            size="sm"
            no-resize
            :value="itemValue"
            :disabled="!isContentValid && !errors.length"
            :placeholder="$t('Text')"
            :state="errors.length ? false : null"
            @focus="onFocusTextarea"
            @input="onInputTextarea"
            @blur="onBlurTextarea"
          />
          <div
            :class="[
              'menu-content-text__limit-counter',
              { 'menu-content-text__limit-counter_visible': isTextareaInFocus || errors.length },
              { 'text-danger': errors.length }
            ]"
          >
            <small>{{ itemValue.length }} / {{ textLimit }}</small>
          </div>
        </validation-provider>
      </div>
    </b-col>
    <b-col
      cols="auto"
      class="d-flex align-items-center"
    >
      <feather-icon
        :class="['card-content__transparent', { 'feather-disabled': !isContentValid }]"
        role="button"
        size="20"
        icon="TrashIcon"
        @click="$emit('delete')"
      />
    </b-col>
  </b-row>
</template>

<script>
import {
  BRow,
  BCol,
  BFormTextarea,
} from 'bootstrap-vue'
import { ValidationProvider } from 'vee-validate'
import { max } from '@validations'

export default {
  name: 'MenuContentText',
  components: {
    BRow,
    BCol,
    BFormTextarea,
    ValidationProvider,
  },
  props: {
    item: {
      type: Object,
      required: true,
      default: () => ({ id: '', type: 'Text', value: '' }),
    },
    textLimit: {
      type: Number,
      required: true,
      default: 250,
    },
    isContentValid: {
      type: Boolean,
      required: true,
      default: true,
    },
  },
  data() {
    return {
      isTextareaInFocus: false,

      // validation rules
      max,
    }
  },
  computed: {
    itemValue() {
      return this.item.value
    },
  },
  watch: {
    itemValue(newValue) {
      this.updateDatasetInValidationProvider(newValue)
    },
  },
  methods: {
    updateDatasetInValidationProvider(value) {
      this.$refs.vp.$el.dataset.textareaContent = `${value}\n`
    },
    onFocusTextarea() {
      this.updateDatasetInValidationProvider(this.itemValue)
      this.isTextareaInFocus = true
    },
    onInputTextarea(event) {
      this.$emit('textarea-input', event)
    },
    onBlurTextarea() {
      this.$emit('textarea-blur')
      this.isTextareaInFocus = false
    },
  },
}
</script>

<style lang="sass">
@import '@core/scss/base/bootstrap-extended/_variables.scss'

.menu-content-text
  &__container
    flex-grow: 1
    position: relative
    display: grid
    &::after
      content: attr(data-textarea-content)
      padding: $tooltip-padding-y $input-padding-x-sm
      font-size: $small-font-size
      line-height: 1.6rem
      border: 1px solid transparent
      letter-spacing: normal
      white-space-collapse: preserve
      text-wrap: wrap
      overflow-wrap: break-word
      visibility: hidden
      grid-area: 1 / 1 / 2 / 2
      transition: padding-bottom 0.25s ease-in-out
    &_textarea-in-focus
      &::after
        padding-bottom: calc(#{$input-padding-x-sm} * 2)
  &__textarea
    grid-area: 1 / 1 / 2 / 2
    &:focus
      & + .menu-content-text__limit-counter
        opacity: 1
        visibility: visible
  &__limit-counter
    position: absolute
    bottom: $tooltip-padding-y
    right: $input-padding-x-sm
    line-height: 1
    opacity: 0
    visibility: hidden
    transition-duration: 0.25s
    transition-property: visibility, opacity
    transition-timing-function: ease-in-out
    &_visible
      opacity: 1
      visibility: visible
</style>
