var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-row',{staticClass:"menu-content-text"},[_c('b-col',[_c('div',{staticClass:"d-flex align-items-center"},[_c('div',{staticClass:"card-content__move-vertical-icon card-content__transparent mr-1"},[_c('feather-icon',{attrs:{"size":"20","icon":"MoreVerticalIcon"}}),_c('feather-icon',{attrs:{"size":"20","icon":"MoreVerticalIcon"}})],1),_c('validation-provider',{ref:"vp",class:[
          'menu-content-text__container',
          { 'menu-content-text__container_textarea-in-focus': _vm.isTextareaInFocus } ],attrs:{"tag":"div","name":"add_text","vid":_vm.item.id,"rules":("max:" + _vm.textLimit)},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var errors = ref.errors;
return [_c('b-form-textarea',{staticClass:"menu-content-text__textarea",attrs:{"size":"sm","no-resize":"","value":_vm.itemValue,"disabled":!_vm.isContentValid && !errors.length,"placeholder":_vm.$t('Text'),"state":errors.length ? false : null},on:{"focus":_vm.onFocusTextarea,"input":_vm.onInputTextarea,"blur":_vm.onBlurTextarea}}),_c('div',{class:[
            'menu-content-text__limit-counter',
            { 'menu-content-text__limit-counter_visible': _vm.isTextareaInFocus || errors.length },
            { 'text-danger': errors.length }
          ]},[_c('small',[_vm._v(_vm._s(_vm.itemValue.length)+" / "+_vm._s(_vm.textLimit))])])]}}])})],1)]),_c('b-col',{staticClass:"d-flex align-items-center",attrs:{"cols":"auto"}},[_c('feather-icon',{class:['card-content__transparent', { 'feather-disabled': !_vm.isContentValid }],attrs:{"role":"button","size":"20","icon":"TrashIcon"},on:{"click":function($event){return _vm.$emit('delete')}}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }